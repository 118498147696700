<template>
  <div>
    <div></div>
    <div>
      <div class="font-24 mt-10 ml-4">Edit Celebrity Style</div>
      <div class="justify-center" align="center">
        <!-- Top Bar Chart -->
        <v-card class="mt-2 mx-4">
          <div class="panel-content">
            <div class="row">
              <div class="col-md-12">
                <div class="widget">
                  <div class="form-elements-sec">
                    <div class="form-body">
                      <form
                        name="details"
                        class="formPart text-left"
                        @submit.prevent="submitForm(id)"
                      >
                        <!-- Name -->
                        <div>
                          <div class="form-group col">
                            <label for="name">Title:</label>
                            <input
                              type="name"
                              class="form-control"
                              id="title"
                              placeholder="Enter Name"
                              v-model.trim="details.title"
                              @input="setTouch('title')"
                            />
                            <div
                              class="validation"
                              v-if="!$v.details.title.required && $v.details.title.$error"
                            >
                              Name is required
                            </div>
                          </div>
                        </div>

                        <div>
                          <div class="form-group col">
                            <label for="name">Designer:</label>
                            <v-select
                              prepend-icon=""
                              item-text="brandName"
                              v-model="details.user"
                              :items="brands"
                              class="form-control"
                              append-icon="▼"
                            >
                              <template v-slot:selection="{ item }">
                                <v-row no-gutters>
                                  <v-col cols="12">
                                    <div>{{ item.brandName }}</div>
                                  </v-col>
                                </v-row>
                              </template>
                              <template v-slot:item="{ item, attrs, on }">
                                <v-list-item v-on="on" v-bind="attrs">
                                  <v-list-item-content>
                                    <div>{{ item.brandName }}</div>
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                            </v-select>
                            <!-- <div
                              class="validation"
                              v-if="!$v.details.user._id.required && $v.details.user._id.$error"
                            >
                              Designer Name is required
                            </div> -->
                          </div>
                        </div>
                        <!-- <div>
                          <div class="form-group col">
                            <label for="mobile"> Select Image:</label> <br />

                            <v-file-input
                              prepend-icon=""
                              type="file"
                              class="form-control"
                              id="spotlight-image"
                              placeholder="Select Image"
                              v-model.trim="details.image"
                              @change="handleImage"
                              accept="image/jpeg, image/png"
                            ></v-file-input>
                            <div
                              class="validation"
                              v-if="!$v.details.image.required && $v.details.image.$error"
                            >
                              Image is required
                            </div>
                          </div>
                          <div class="preview-image border p-2 mt-3 ml-3" v-if="url">
                            <v-img :src="url" height="230" contain></v-img>
                          </div>
                        </div> -->
                        <div class="d-flex">
                          <div class="form-group col-6">
                            <div>
                              <label for="image">Mobile Image:</label>
                              <div class="font-12 black--text font-weight-bold">
                                (163 W &#10005; 222 H)
                              </div>
                              <v-file-input
                                prepend-icon=""
                                type="file"
                                class="form-control"
                                id="banner-image"
                                v-model.trim="details.imageMobile"
                                placeholder=" Select Image"
                                accept="image/jpeg, image/png"
                                @change="handleMobileImage"
                              ></v-file-input>
                              <div
                                class="validation"
                                v-if="
                                  !$v.details.imageMobile.required && $v.details.imageMobile.$error
                                "
                              >
                                Mobile Image is required
                              </div>
                            </div>
                            <div class="preview-image border p-2 mt-3 ml-3" v-if="mobileUrl">
                              <v-img :src="mobileUrl" height="230" width="100%" contain></v-img>
                            </div>
                          </div>

                          <div class="form-group col-6">
                            <div>
                              <label for="image"> Desktop Image:</label>
                              <div class="font-12 black--text font-weight-bold">
                                (227 W &#10005; 322 H)
                              </div>
                              <v-file-input
                                prepend-icon=""
                                type="file"
                                class="form-control"
                                id="banner-image"
                                v-model.trim="details.image"
                                placeholder=" Select Image"
                                @change="handleDesktopImage"
                                accept="image/jpeg, image/png"
                              ></v-file-input>
                              <div
                                class="validation"
                                v-if="!$v.details.image.required && $v.details.image.$error"
                              >
                                Desktop Image is required
                              </div>
                            </div>
                            <div class="preview-image border p-2 mt-3 mr-3" v-if="desktopUrl">
                              <v-img :src="desktopUrl" height="230" contain></v-img>
                            </div>
                          </div>
                        </div>

                        <div>
                          <div class="form-group col">
                            <label for="name">Palettier SKU Code:</label>
                            <v-text-field
                              width="150px"
                              outlined
                              dense
                              class="mt-6 mr-4 px-2"
                              v-model="details.sku"
                              item-text="name"
                            ></v-text-field>
                            <!-- <div
                              class="validation"
                              v-if="!$v.details.sku.required && $v.details.sku.$error"
                            >
                              SKU is required
                            </div> -->
                          </div>
                        </div>
                        <div>
                          <div class="form-group col">
                            <label for="link">Link:</label>
                            <input
                              type="text"
                              class="form-control"
                              id="link"
                              placeholder="Enter Url"
                              v-model.trim="details.link"
                            />
                            <!-- <div
                              class="validation"
                              v-if="!$v.details.link.required && $v.details.link.$error"
                            >
                              Link is required
                            </div> -->
                          </div>
                        </div>
                        <!-- button -->
                        <div class="btnPart text-center my-4">
                          <button type="submit" class="btn btn-success">Save</button>
                          <p class="typo__p" v-if="submitStatus === 'OK'">
                            Thanks for your submission!
                          </p>
                          <p class="typo__p" v-if="submitStatus === 'ERROR'">
                            Please fill the form correctly.
                          </p>
                          <button type="reset" class="btn btn-danger mx-3" v-on:click="resetForm()">
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import homeService from '../../services/homeService';
import imageUploadService from '../../services/imageUploadService';
import productService from '../../services/productService';

export default {
  components: {},
  data() {
    return {
      details: {
        title: '',
        image: '',
        imageMobile: '',
        link: '',
        sku: '',
        product: '',
        user: '',
      },
      submitStatus: false,
      id: '',
      mobileUrl: null,
      desktopUrl: null,
      brands: [],
    };
  },
  validations: {
    details: {
      title: {
        required,
      },
      image: {
        required,
      },
      imageMobile: {
        required,
      },
      // link: {
      //   required,
      // },
      // sku: {
      //   required,
      // },
      // user: {
      //   required,
      // },

      //   product: {
      //     required,
      //   },
    },
  },
  async created() {
    await this.getAllBrands();
    this.id = this.$route.params.id;
    if (this.id) {
      this.getOneSpotLight(this.id);
    }
  },
  methods: {
    async findBrandId(name) {
      const finalLayer = await _.find(this.brands, (n) => {
        const obj = n.brandName === name;
        return obj;
      });
      console.log('finalLayer', finalLayer);
      return finalLayer._id;
    },
    setTouch(fields) {
      if (fields === 'title') {
        this.$v.details.title.$touch();
      } else if (fields === 'image') {
        this.$v.details.image.$touch();
      } else if (fields === 'imageMobile') {
        this.$v.details.imageMobile.$touch();
      }
      // else if (fields === 'link') {
      //   this.$v.details.link.$touch();
      // }
      // else if (fields === 'sku') {
      //   this.$v.details.sku.$touch();
      // }
    },
    // this method is  used for save button
    async submitForm(id) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$store.commit('setSnackbar', {
          content: 'please fill form correctly',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });

        this.submitStatus = 'false';
      } else {
        this.submitStatus = 'true';
        console.log(this.details.user, 'details');
        this.details.user = await this.findBrandId(this.details.user);
        const result = await homeService.updateSpotLight(id, this.details);
        if (result.status === 200) {
          this.$store.commit('setSnackbar', {
            content: 'Submitted Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
          this.$router.push('/viewSpotLight');
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
      }
    },

    async handleDesktopImage(file) {
      this.desktopUrl = URL.createObjectURL(this.details.image);
      imageUploadService.uploadImage(file, (result) => {
        if (result.status === 200) {
          this.details.image = result.data.meta.location;
          this.$store.commit('setSnackbar', {
            content: 'Image Uploaded Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
      });
    },
    async getOneSpotLight(id) {
      const result = await homeService.getOneSpotLight(id);
      this.details.title = result.data.title;
      this.details.image = result.data.image;
      this.details.imageMobile = result.data.imageMobile;
      this.details.link = result.data.link;
      this.desktopUrl = result.data.image;
      this.mobileUrl = result.data.imageMobile;
      this.details.sku = result.data.sku;
      this.details.user = await this.findBrandName(result.data.user._id);
      this.details.product = result.data.product;
      console.log(this.details.user, 'details-user');
    },

    resetForm() {
      this.details = 'blank';
      this.$nextTick(() => {
        this.$v.$reset();
        this.$router.push('/viewSpotLight');
      });
    },
    deleteSKU(index) {
      this.details.query.splice(index, 1);
    },
    async handleImage(file) {
      this.desktopUrl = URL.createObjectURL(this.details.image);
      imageUploadService.uploadImage(file, (result) => {
        if (result.status === 200) {
          this.details.image = result.data.meta.location;
          this.$store.commit('setSnackbar', {
            content: 'Image Uploaded Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
      });
    },
    async handleMobileImage(file) {
      this.mobileUrl =  URL.createObjectURL(this.details.imageMobile);

      imageUploadService.uploadImage(file, (result) => {
        if (result.status === 200) {
          console.log(result.data.meta.location, 'meta');
          this.details.imageMobile = result.data.meta.location;

          console.log(this.details.image, 'meta2');
          this.$store.commit('setSnackbar', {
            content: 'Image Uploaded Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
      });
    },

    async findBrandName(id) {
      const finalLayer = await _.find(this.brands, (n) => {
        const obj = n._id === id;
        return obj;
      });
      return finalLayer.brandName;
    },

    async getAllBrands() {
      const result = await productService.getAllBrands();
      this.brands = await result.data;
      console.log(this.brands, 'brands');
    },
  },
};
</script>

<style lang="scss" scoped>
.validation {
  color: red;
}
.preview-image {
  width: 40%;
  height: 250px;
}
</style>
